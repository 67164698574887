<template>
  <div class="container">
    <div v-if="error == ''" class="child">
      <p class="tit1">为保证您的信息安全</p>
      <p class="tit2">请进行人脸拍照认证</p>
      <div class="tip">
        <img class="left" src="../images/tip.svg" alt="" />
        <div class="right">
          <p>确保本人操作</p>
          <p>非本人操作将无法通过认证</p>
        </div>
      </div>
      <div class="tip">
        <img class="left" src="../images/tip.svg" alt="" />
        <div class="right">
          <p>识别光线适中</p>
          <p>请保证光线不要过暗或过亮</p>
        </div>
      </div>
      <div class="tip">
        <img class="left" src="../images/tip.svg" alt="" />
        <div class="right">
          <p>正面对准手机</p>
          <p>保持您的脸出现在取景框内</p>
        </div>
      </div>
      <van-button
        v-if="checked"
        style="margin: 80px 0 20px"
        size="normal"
        round
        color="#f49b0d"
        block
        >开始身份认证
        <input
          type="file"
          accept="image/*"
          capture="user"
          class="file-btn"
          @change="uploadImg($event)"
        />
      </van-button>
      <div style="width: 100%" @click="start" v-else>
        <van-button
          style="margin: 80px 0 20px"
          size="normal"
          round
          color="#f49b0d"
          :disabled="true"
          block
          >开始身份认证
        </van-button>
      </div>
      <div class="agreement">
        <van-checkbox shape="square" checked-color="#f49b0d" icon-size="16px" v-model="checked">
          <p class="file">
            我已阅读并同意<span @click="realNameAuthentication">《实名认证用户隐私协议》</span>
          </p>
        </van-checkbox>
      </div>
    </div>
    <p class="errtxt" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { getItem, identifyFace } from "../api/index";
import Compressor from "compressorjs";
var showLoading = null; //定义toast的变量
export default {
  name: "Index",
  data() {
    return {
      error: null,
      checked: false,
      faceBase: "", //照片数据
      address: "", //获取定位具体地址
    };
  },
  created() {
    this.getItem();
  },
  beforeDestroy() {
    this.$bus.$emit("faceBaseData", { faceBase: this.faceBase });
  },
  methods: {
    //获取定位
    getAmap() {
      var that = this;
      AMap.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          needAddress: true,
        });
        geolocation.getCurrentPosition((status, result) => {
          //获取用户当前的精确位置
          if (status == "complete") {
            showLoading.close(); //请求结束关闭加载
            that.address = result.formattedAddress;
            let obj = {
              longitude: result.position.lng,
              latitude: result.position.lat,
              currentAddress: that.address,
            };
            sessionStorage.setItem("location", JSON.stringify(obj));
            that.$dialog
              .alert({
                title: "当前定位",
                message: that.address,
                confirmButtonColor: "#1989fa",
              })
              .then(() => {
                // 对话框关闭时的回调
              });
          }else{
            showLoading.close(); //请求结束关闭加载
          }
        });
      });
    },
    //实名认证用户隐私协议
    realNameAuthentication() {
      this.$router.push({
        path: "/agreement/realNameAuthentication",
      });
    },
    start() {
      this.$toast(`请您先阅读实名认证用户隐私协议`);
    },
    //h5人脸识别
    identifyFace(faceBase) {
      let location = JSON.parse(sessionStorage.getItem("location"))
      this.faceBase = faceBase;
      let data = {
        projectId: this.$route.query.id, //项目组ID
        itemId: this.$route.query.itemId, //立达项目ID
        faceBase: faceBase,
      };
      showLoading = this.$toast({
        type: "loading",
        message: "识别中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner",
      });
      identifyFace({...data,...location})
        .then(res => {
          showLoading.close(); //请求结束关闭加载
          if (res.data) {
            // 成功
            this.$router.push({
              path: "/success",
            });
          } else {
            // 人脸识别失败，走身份校验流程
            this.$toast(`${res.msg}，请进行身份认证`);
            this.$router.push({
              path: "/identityAuthentication",
              query: {
                id: this.$route.query.id, //项目组ID
                itemId: this.$route.query.itemId, //立达项目ID
              },
            });
          }
        })
        .catch(err => {
          showLoading.close(); //请求结束关闭加载
          if (err.code == 500) {
            //异常提示，跳转到失败页面
            this.$toast(`${err.msg}`);
            this.$router.push({
              path: "/error",
            });
          } else if (err.code == 203) {
            //人脸对比失败，重新进行人脸识别
            this.$toast(`${err.msg}，请重新进行人脸识别`);
          }
        });
    },
    //获取图片base64编码
    uploadImg(e) {
      let that = this;
      let file = e.srcElement.files[0]; //获取到上传文件的对象
      new Compressor(file, {
        quality: 1, // 压缩质量
        maxWidth: 1920, // 压缩后的最大宽度
        maxHeight: 1080, // 压缩后的最大高度
        success(result) {
          var reader = new FileReader();
          reader.readAsDataURL(result); //参数为上传的文件对象 传值进去就会触发以下onload方法
          reader.onload = function (es) {
            let base = es.target.result.replace(/^data:image\/\w+;base64,/, "");
            that.identifyFace(base);
          };
        },
        error(err) {
          console.log(err);
          this.$toast(`图片压缩失败，请稍后重试！`);
        },
      });
    },
    //查询项目状态（是否可签到）
    getItem() {
      showLoading = this.$toast({
        type: "loading",
        message: "数据加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner",
      });
      let params = {
        projectId: this.$route.query.id, //项目组ID
        itemId: this.$route.query.itemId, //立达项目ID
      };
      getItem(params)
        .then(() => {
          this.error = "";
          this.getAmap();
        })
        .catch(err => {
          this.error = err.msg || "项目信息获取失败";
          showLoading.close(); //请求结束关闭加载
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 24px;

  .child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .agreement {
    padding: 0 15px;
  }
  .file {
    font-size: 14px;
    line-height: 14px;
    color: #666 !important;
    span {
      color: #f26e21;
      text-decoration: underline;
    }
  }
  .tit1 {
    font-size: 16px;
    line-height: 16px;
    color: #333;
    margin-top: 50px;
  }
  .tit2 {
    font-size: 24px;
    line-height: 24px;
    color: #333;
    margin: 20px 0 80px;
  }
  .tip {
    display: flex;
    align-items: center;
    margin: 10px 0 20px;
    .left {
      width: 30px !important;
      margin-right: 10px;
    }
    .right {
      p:first-child {
        font-size: 16px;
        line-height: 16px;
        color: #666;
      }
      p:last-child {
        font-size: 14px;
        line-height: 14px;
        color: #666;
        margin-top: 10px;
      }
    }
  }
  .file-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  .errtxt {
    font-size: 14px;
    line-height: 18px;
    color: #f40d0e;
    text-align: center;
    padding: 30px;
  }
}
</style>
